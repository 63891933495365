import { Routes, Route } from 'react-router-dom';
import loadable from '@loadable/component';

import Navigation from './components/navigation/Navigation';

const Home = loadable(() => import('./pages/home/Home'));
const Biography = loadable(() => import('./pages/biography/Biography'));
const Videos = loadable(() => import('./pages/videos/Videos'));
const Books = loadable(() => import('./pages/books/Books'));
const Deepening = loadable(() => import('./pages/deepening/Deepening'));
const Design = loadable(() => import('./pages/design/Design'));
const Archives = loadable(() => import('./pages/archives/Archives'));
const News = loadable(() => import('./pages/news/News'));
const NewsDetail = loadable(() => import('./pages/newsdetail/NewsDetail'));

const look = require('./client-data.json');

const App = () => {
  return (
    <div className="App">
      <div className="mtt-content">
        <Navigation navigation={look.navigation} />
        <Routes>
          <Route path="/" element={<Home home={look.home} />} />
          <Route
            path="biography/:yid"
            element={<Biography biography={look.biography} />}
          />
          <Route path="videos" element={<Videos videos={look.videos} />} />
          <Route path="books" element={<Books books={look.books} />} />
          <Route
            path="deepening/:did"
            element={<Deepening deepening={look.deepening} />}
          />
          <Route path="design" element={<Design design={look.design} />} />
          <Route
            path="archives"
            element={<Archives archives={look.archives} />}
          />
          <Route path="news" element={<News news={look.news} />} />
          <Route path="news/:nid" element={<NewsDetail news={look.news} />} />
        </Routes>
      </div>
      <footer className="mtt-footer bg-white">
        <div className="container py-3">
          <div className="text-center">
            <h4>
              <strong>{look.home.info.subtitle}</strong>
            </h4>
            <h5>{`${look.home.info.fraction} | ${look.home.info.locality} | ${look.home.info.city} | ${look.home.info.nation}`}</h5>
            <h5>
              {`${look.home.info.call}`}
              <a href={`tel:${look.home.info.tel}`} className="mtt-link">
                {look.home.info.tel}
              </a>
            </h5>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;
