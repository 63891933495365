import { Link } from 'react-router-dom';

import Sign from './assets/sign.png';
import Logo from './assets/logo.png';

const Navigation = ({ navigation }) => {
  return (
    <div className="mtt-header sticky-top" id="navigation">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container bg-white">
          {/* Logo */}
          <span className="navbar-brand">
            <img
              className="brand-img"
              src={Sign}
              alt="Sign"
              width="305"
              height="108"
              loading="lazy"
            />
          </span>

          {/* Overflow Button */}
          <button
            className="navbar-toggler p-1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img
              className="toggler-img"
              src={Logo}
              alt="Logo"
              width="80"
              height="80"
              loading="lazy"
            />
          </button>

          {/* Options */}
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              {navigation.opts.map((opt, position) => (
                <li
                  className="nav-item"
                  key={'navigation-' + position}
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                >
                  <Link
                    className="nav-link btn btn-link text-uppercase fs-4"
                    to={opt.path}
                  >
                    <strong>{opt.label}</strong>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;
